/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { ContentStackLegal } from '@/features/contentstack/interface';

export const mapContentstackLegalValues = ({ text, link_name, copy, link }: ContentStackLegal) => ({
  legalText: text,
  legalLinkName: link_name,
  legalCopy: copy,
  legalLink: link,
});
