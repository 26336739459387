/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Carousel from '@/components/Carousel';
import type {
  ContentStackSuperDuperHero,
  ContentStackHorizontalScrollCards,
} from '@/features/contentstack/interface';
import { useMemo, useState } from 'react';
import { CarouselItem } from '../../interface';
import { CarouselImage } from '../HomepageHero/CarouselImage';
import { useCitrusHomepageData } from '@/features/citrus/api/getCitrusHomepageData';
import { reportCitrusImpression } from '@/features/citrus/utils/reportCitrusImpression';
import { mapCitrusBannerToContentStackBanner } from '@/features/citrus/utils/mapCitrusBannerToContentStackBanner';
import styles from './SuperDuperHero.module.scss';
import cx from 'classnames';
import CarouselTopSection from './CarouselTopSection';
import HorizontalCards from './HorizontalCards';
import { ariaLabelForCurrentSlide } from './SuperDuperHelpers';
import { isCitrusBannerWithLink } from '@/features/citrus/utils/isCitrusBannerWithLink';
import { useFeatureFlag } from '@/features/globalConfig';

const DEFAULT_ROTATION_SPEED_SECS = 5;

type Props = {
  options: ContentStackSuperDuperHero;
  horizontalCards?: ContentStackHorizontalScrollCards['card_content'];
};

const SuperDuperHero = ({ options, horizontalCards }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideToImage, setSlideToImage] = useState('');
  const [autoRotationEnabled, setAutoRotationEnabled] = useState(true);
  const citrusHeroEnabled = useFeatureFlag('IS_CITRUS_HERO_ENABLED') === 'true';
  const { data: citrusData } = useCitrusHomepageData({
    enabled: citrusHeroEnabled,
  });

  const [carouselItems, carouselItemCount]: [CarouselItem[], number] = useMemo(() => {
    const carouselBanner =
      citrusData?.ads.find((item) => item.slotId === 'carousel-banner')?.banners ?? [];

    const citrusCarouselBanners = carouselBanner
      .map((item) => {
        if (!isCitrusBannerWithLink(item)) {
          return null;
        }

        const cmsBanner = mapCitrusBannerToContentStackBanner(item);
        return {
          image: cmsBanner.image,
          link: cmsBanner.link,
          legal: cmsBanner.legal,
          citrusInfo: {
            citrusAdId: item.citrusAdId,
          },
        };
      })
      .filter(Boolean) as CarouselItem[];

    const cmsCarouselBanners = options?.carousel_content ?? [];

    const items = [
      ...cmsCarouselBanners.slice(0, 1),
      ...(citrusHeroEnabled && !options?.hero_banner ? citrusCarouselBanners.slice(0, 5) : []),
      ...cmsCarouselBanners.slice(1),
    ];
    return [items, items.length];
  }, [citrusData?.ads, options?.carousel_content, options?.hero_banner, citrusHeroEnabled]);

  const handleImageChange = (index: number) => {
    if (index !== currentSlide) {
      setCurrentSlide(index);
      setSlideToImage('');
    }
    const currentItem = carouselItems[index];
    if ('citrusInfo' in currentItem && currentItem.citrusInfo?.citrusAdId) {
      void reportCitrusImpression(currentItem.citrusInfo.citrusAdId);
    }
  };

  const superDuperHeroBannerAriaLabel = ariaLabelForCurrentSlide({
    part: currentSlide + 1,
    whole: carouselItemCount,
    altText: carouselItems[currentSlide].image?.image_alt_text,
  });

  return (
    <div
      className={cx(styles.superDuperHeroContainer, {
        [styles.heroBanner]: options.hero_banner,
      })}
      role="banner"
      aria-label={superDuperHeroBannerAriaLabel}
      tabIndex={0}
    >
      <div className={styles.superDuperHero}>
        <Carousel
          autoRotationEnabled={autoRotationEnabled}
          showCarouselDots={false}
          autoRotationSpeed={(options.auto_rotation_speed ?? DEFAULT_ROTATION_SPEED_SECS) * 1000}
          classes={{ root: cx({ [styles.carouselRoot]: !options.hero_banner }) }}
          onImageChange={handleImageChange}
          controlled
          selectedThumbnailIndex={currentSlide}
          slideToImage={slideToImage}
        >
          {carouselItems?.map((colItem, index) => (
            <CarouselImage
              key={colItem.image?.image_url ?? index}
              blockItem={colItem}
              itemIndex={index}
              tabindex={-1}
              isCurrentSlide={currentSlide === index}
              carouselImageClassName={
                options.hero_banner ? styles.carouselImage : styles.carouselImageXL
              }
            />
          ))}
        </Carousel>
        <CarouselTopSection
          carouselItems={carouselItems}
          carouselItemCount={carouselItemCount}
          currentSlide={currentSlide}
          autoRotationEnabled={autoRotationEnabled}
          setCurrentSlide={setSlideToImage}
          setAutoRotationEnabled={setAutoRotationEnabled}
          heroBanner={!!options.hero_banner}
        />
        {horizontalCards && <HorizontalCards horizontalCardsData={horizontalCards} />}
      </div>
    </div>
  );
};

export default SuperDuperHero;
