/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackLegal } from '../interface/content-types';

export const isLegalDefined = (legal: ContentStackLegal | unknown | undefined) => {
  if (!legal) {
    return false;
  }

  const fields = ['copy', 'link', 'link_name', 'text'];

  return fields.some(
    (field) => typeof legal === 'object' && field in legal && legal[field as keyof typeof legal]
  );
};
