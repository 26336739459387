/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import environment from '@/config/environment';

export function getCitrusCatalogId(forceProd: boolean) {
  return environment.TARGET_ENV === 'production' || forceProd
    ? '86e0cbcb-cb7f-4b20-801f-def2165dccea'
    : '5cd5f0d0-332e-4c23-b8d0-db983d3dd61c';
}
