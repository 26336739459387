/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import environment from '@/config/environment';

export function getCitrusContentStandardId(forceProd: boolean) {
  return environment.TARGET_ENV === 'production' || forceProd
    ? 'b45d0eba-b209-440c-9325-d3302fb9b861'
    : '54d24de1-b8a0-4d1f-a355-ed586f40023a';
}
