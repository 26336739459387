/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ReduxState, PaymentOptionList, AddressDetails } from '@/types';
import type {
  OrdersDetailsOrderSummaryFromState,
  OrdersDetailsFromState,
  OrderItemsFromState,
  OrdersDetailsPaymentInfoList,
} from '@/types/reduxStore/orders';
import environmentConfig from '../config/environment';
import logger from '../utils/logger';
import type { Locale } from '@/types/i18n';
import { countryCodes } from './locale';
// we do not have an object for the whole state

// appData
export const getUserAuthenticated = (state: ReduxState): boolean => state.appData.userAuthenticated;

export const getMetrixUrl = (): string => {
  const metrix_url = environmentConfig.METRIX_URL ?? '';
  if (metrix_url === '') {
    logger.error({ message: 'METRIX_URL missing in .env variables' });
  }
  return metrix_url;
};

export const getMerchantId = (locale: Locale): string => {
  const merchantId =
    (locale === countryCodes.enUS
      ? environmentConfig.CYBERSOURCE_MERCHANT_ID
      : environmentConfig.CYBERSOURCE_MERCHANT_ID_MX) ?? '';
  if (merchantId === '') {
    logger.error({
      message: 'CYBERSOURCE_MERCHANT_ID missing in .env variables',
    });
  }
  return merchantId;
};

export const getLoqateKey = (locale: Locale): string => {
  const loqateId =
    (locale === countryCodes.enUS
      ? environmentConfig.LOQATE_KEY
      : environmentConfig.LOQATE_KEY_MX) ?? '';
  if (loqateId === '') {
    logger.error({
      message: 'LOQATE_KEY or LOQATE_KEY_MX missing in .env variables',
    });
  }
  return loqateId;
};

export const getWorldPayPublicKey = (): string => {
  const publicKey = environmentConfig.WORLD_PAY_PUBLIC_KEY ?? '';
  if (publicKey === '') {
    logger.error({
      message: 'WORLD_PAY_PUBLIC_KEY missing in .env variables',
    });
  }
  return publicKey;
};

export const getOrgId = (): string => {
  const orgId = environmentConfig.CYBERSOURCE_ORG_ID ?? '';
  if (orgId === '') {
    logger.error({ message: 'CYBERSOURCE_ORG_ID missing in .env variables' });
  }
  return orgId;
};

// checkout
export const getCheckoutSubscriptionTextMessage = (state: ReduxState): boolean =>
  state.checkout?.checkoutSubscriptions?.receiveTextMessage;

export const getCheckoutSubscriptionSpecialEvents = (state: ReduxState): boolean =>
  state.checkout?.checkoutSubscriptions?.receivePromoAndSpecialEvents;

export const getCheckoutPaymentStatus = (state: ReduxState): boolean =>
  state.checkout?.paymentStatus;

export const getCheckoutCheckPaymentStatus = (state: ReduxState): boolean =>
  state.checkout?.checkPaymentStatus;

// orders
export const selectOrderDetailsFromState = (state: ReduxState): OrdersDetailsFromState =>
  state.orders?.details;

export const selectOrdersDetailsOrderSummary = (
  state: ReduxState
): OrdersDetailsOrderSummaryFromState | null => state.orders.details.orderSummary;

export const selectOrdersDetailsOrderItems = (state: ReduxState): OrderItemsFromState =>
  state.orders?.details?.orderItems;

export const getOrdersDetailsPaymentInfoList = (state: ReduxState): OrdersDetailsPaymentInfoList =>
  state.orders.details?.paymentInfoList;

export const selectOrdersAddressDetails = (state: ReduxState): AddressDetails =>
  state.orders.addressDetails;

export const selectOrdersDetailsReadyStatus = (state: ReduxState): string =>
  state.orders.details?.readyStatus;

// paymentOption
export const getPaymentOptionList = (state: ReduxState): PaymentOptionList =>
  state.paymentOption?.paymentOptionList;

export const getPaymentOptionReadyStatus = (state: ReduxState): string =>
  state.paymentOption?.readyStatus;

// cartUpdate
export const selectCartUpdate = (state: ReduxState) => state.cartUpdate;
