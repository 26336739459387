/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { View, Skeleton } from '@az/starc-ui';
import styles from './styles.module.scss';
import Hidden from '@/components/Hidden';
import cx from 'classnames';
import SkeletonImg from '@/public/images/certonaskeletonimg.svg';
import NextImage from '@/components/NextImage';

const PartCard = () => (
  <View borderRadius="small" borderColor="disabled" padding={3} className={styles.partCard}>
    <View.Item>
      <SkeletonImg alt="placeholder-product-image" />
    </View.Item>
    <View.Item grow>
      <View gap={1}>
        <Skeleton height="22px" />
        <Skeleton width="80%" height="19px" />
        <Skeleton height="22px" />
        <View.Item gapBefore={2}>
          <Skeleton width="65%" height="22px" />
        </View.Item>
        <View.Item gapBefore={3}>
          <Skeleton borderRadius="small" noAnimation className={styles.ctaSkeleton} height="33px" />
        </View.Item>
      </View>
    </View.Item>
  </View>
);

export const ScrolledPartCardSkeleton = () => {
  return (
    <View
      direction="row"
      backgroundColor="secondary"
      padding={[4, 0]}
      gap={6}
      className={styles.skeletonContainer}
      align="center"
    >
      <View.Item className={styles.title}>
        <Skeleton height="44px" borderRadius="small" />
      </View.Item>
      <View.Item className={styles.partsWithArrow}>
        <View.Item>
          <View
            gap={2}
            direction="row"
            attributes={{ style: { margin: 0 } }}
            className={styles.partsContainer}
          >
            {new Array(10).fill(1).map((_, i) => (
              <PartCard key={i} />
            ))}
          </View>
        </View.Item>
        <Hidden smDown>
          <div
            role="button"
            data-testid="carousel-right-arrow"
            className={cx(styles.rightArrow)}
            tabIndex={0}
          >
            <NextImage
              className={styles.chevronImg}
              src="/images/arrow_right_initial.svg"
              alt="scroll carousel right"
              width={56}
              height={62}
            />
            <NextImage
              className={styles.chevronImgHover}
              src="/images/arrow_right_hover.svg"
              alt=""
              width={56}
              height={62}
            />
          </div>
        </Hidden>
      </View.Item>
    </View>
  );
};
