/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CMSLink } from '../../CMSLink/CMSLink';
import type { ContentStackParttypeGrid } from '../../../interface';
import styles from './styles.module.scss';
import { CMSImage } from '../../CMSImage/CMSImage';
import cx from 'classnames';

type GridItemProps = {
  item: NonNullable<ContentStackParttypeGrid['grid_content']>[number];
  onLinkClick: (linkLabel: string, link: string) => void;
  layout: ContentStackParttypeGrid['layout'];
  gridLines?: boolean;
};

const imgSizesForGridLines = {
  desktop: {
    width: 106,
    height: 106,
  },
  mobile: {
    width: 100,
    height: 100,
  },
  tablet: {
    width: 85,
    height: 95,
  },
};

export const CMSGridItem = ({ item, onLinkClick, layout, gridLines }: GridItemProps) => {
  const { link, image, label } = item;

  return (
    <CMSLink
      to={link || '#'}
      onClick={() => {
        onLinkClick(label ?? link, link);
      }}
      samePage={true}
      className={cx(
        styles.link,
        gridLines ? styles.linkLines : null,
        styles[`grid-items-${layout.toLowerCase()}${gridLines ? '-lines' : ''}`],
        gridLines ? styles.gridItemLines : null
      )}
    >
      <div className={cx(gridLines ? styles.gridImageContainer : styles.imageContainer)}>
        {image && (
          <CMSImage
            image={image}
            className={styles.image}
            sizePerDevice={gridLines ? imgSizesForGridLines : undefined}
          />
        )}
      </div>
      {label && (
        <div className={cx(styles.gridItemText, gridLines ? styles.gridItemTextLines : null)}>
          {label}
        </div>
      )}
    </CMSLink>
  );
};
