/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Legal } from '@/components/AZCustomComponent/Legal';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import { Button } from '@/components/Button';
import Image from '@/components/NextImage';
import { CarouselItem } from '../../interface';
import styles from './CarouselTopSection.module.scss';
import type { ContentStackLegal } from '@/features/contentstack/interface';
import { isLegalDefined } from '@/features/contentstack/utils/isLegalDefined';
import { Text } from '@az/starc-ui';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ariaLabelForCurrentSlide, useLegalLinkColor } from './SuperDuperHelpers';
import root from 'window-or-global';
import cx from 'classnames';

type Props = {
  carouselItems: CarouselItem[];
  carouselItemCount: number;
  currentSlide: number;
  autoRotationEnabled: boolean;
  setCurrentSlide: Dispatch<SetStateAction<string>>;
  setAutoRotationEnabled: Dispatch<SetStateAction<boolean>>;
  heroBanner: boolean;
};
const CarouselTopSection = (props: Props) => {
  const {
    carouselItems,
    carouselItemCount,
    currentSlide,
    autoRotationEnabled,
    setCurrentSlide,
    setAutoRotationEnabled,
    heroBanner,
  } = props;
  const carouselItem = carouselItems[currentSlide];
  const isWithLegalText =
    !!carouselItem?.legal && ('text' in carouselItem.legal || 'legalText' in carouselItem.legal);
  const [nextButtonFocused, setNextButtonFocused] = useState(false);
  const [previousButtonFocused, setPreviousButtonFocused] = useState(false);
  const [announceCurrentSlide, setAnnounceCurrentSlide] = useState(false);
  const legalLinkColor = useLegalLinkColor(carouselItem);
  const slideRef = useRef(currentSlide);

  const slideNext = () => {
    setCurrentSlide('NEXT');
    setAnnounceCurrentSlide(false);
  };
  const slidePrev = () => {
    setCurrentSlide('PREV');
    setAnnounceCurrentSlide(false);
  };
  const pauseSlider = () => void setAutoRotationEnabled(false);
  const playSlider = () => void setAutoRotationEnabled(true);

  const currentSlideAriaLabel = () => {
    if (
      (nextButtonFocused || previousButtonFocused) &&
      announceCurrentSlide &&
      slideRef.current === currentSlide
    ) {
      return ariaLabelForCurrentSlide({
        part: currentSlide + 1,
        whole: carouselItems.length,
        altText: carouselItems[currentSlide].image?.image_alt_text,
      });
    }
  };

  useEffect(() => {
    if (nextButtonFocused || previousButtonFocused) {
      slideRef.current = currentSlide;
      root.addEventListener('click', () => {
        setAnnounceCurrentSlide(true);
      });
    }
    return () => {
      setAnnounceCurrentSlide(false);
      root.removeEventListener('click', () => {});
    };
  }, [nextButtonFocused, previousButtonFocused, currentSlide]);

  return (
    <>
      <Button
        className={styles.previousButton}
        onClick={slidePrev}
        onFocus={() => setPreviousButtonFocused(true)}
        onBlur={() => setPreviousButtonFocused(false)}
        aria-label={currentSlideAriaLabel()}
      >
        <Image
          className={styles.header_arrow}
          src="/images/right-arrow-grey.svg"
          alt="Previous Slide"
          width={12}
          height={22}
          loading="lazy"
        />
      </Button>

      <Button
        className={styles.nextButton}
        onClick={slideNext}
        onFocus={() => setNextButtonFocused(true)}
        onBlur={() => setNextButtonFocused(false)}
        aria-label={currentSlideAriaLabel()}
      >
        <Image
          className={styles.header_arrow}
          src="/images/left-arrow-grey.svg"
          alt="Next Slide"
          width={12}
          height={22}
          loading="lazy"
        />
      </Button>

      <div className={`${styles.controlsContainer} ${heroBanner ? styles.heroBannerControl : ''}`}>
        {carouselItem?.legal && isLegalDefined(carouselItem.legal) ? (
          <div
            className={cx(styles.legalHyperLink, {
              [styles.raisedControlsPosition]: !heroBanner,
            })}
          >
            <Legal
              content={{
                ...mapContentstackLegalValues(carouselItem.legal as ContentStackLegal),
                imageLink: carouselItem.link,
              }}
              isQuotient={false}
              handleToggleLegalModal={(isModalOpened) => {
                isModalOpened ? pauseSlider() : playSlider();
              }}
              isWithLegalText={isWithLegalText}
              isHeroCarouselBanner
              isCMSHeroBanner
              isCMSSuperDuperHero={true}
              singleLine
              linkColor={legalLinkColor}
            />
          </div>
        ) : null}
        <div className={cx(styles.mediaControl, { [styles.raisedControlsPosition]: !heroBanner })}>
          <Text size="062">
            {currentSlide + 1} of {carouselItemCount}
          </Text>
          {autoRotationEnabled ? (
            <Button onClick={pauseSlider}>
              <Image
                className={styles.header_arrow}
                src="/images/media-pause.svg"
                alt="Pause Slider"
                width={24}
                height={24}
                loading="lazy"
              />
            </Button>
          ) : (
            <Button onClick={playSlider}>
              <Image
                className={styles.header_arrow}
                src="/images/play-arrow.svg"
                alt="Play Slider"
                width={24}
                height={24}
                loading="lazy"
              />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CarouselTopSection;
