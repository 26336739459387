/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { memo } from 'react';
import type { CarouselItem } from '../../interface';
import { CMSLink } from '@/features/contentstack/components/CMSLink/CMSLink';
import carouselImageStyles from './CarouselImage.module.scss';
import { trackHomeCarouselInteraction } from '@/utils/analytics/track/trackHomeCarouselInteraction';
import { useResponsiveImageData } from '@/features/contentstack/hooks/useResponsiveImageData';
import { Picture } from '@/components/Picture/Picture';
import { reportCitrusClick } from '@/features/citrus/utils/reportCitrusClick';
import cx from 'classnames';

type Props = {
  blockItem: CarouselItem;
  itemIndex?: number;
  isCurrentSlide: boolean;
  tabindex?: number;
  setFocusToAnchorTag?: boolean;
  carouselImageClassName?: string;
};

const sizePerDevice = {
  mobile: {
    width: '100%',
    height: '100%',
  },
  tablet: {
    width: '100%',
    height: '100%',
  },
  desktop: {
    width: '100%',
    height: '100%',
  },
};

export const CarouselImage = memo<Props>(function CarouselImage(props: Props) {
  const { setFocusToAnchorTag, blockItem, itemIndex = 0, tabindex } = props;
  const imageData = useResponsiveImageData({
    image: blockItem.image,
    sizePerDevice,
  });

  const onBannerBtnClick = (bannerName: string, slideNumber: number) => {
    if ('citrusInfo' in blockItem && blockItem.citrusInfo?.citrusAdId) {
      void reportCitrusClick(blockItem.citrusInfo.citrusAdId);
    } else {
      trackHomeCarouselInteraction(
        bannerName,
        slideNumber,
        `Home Hero Banner: Rank ${slideNumber}`
      );
    }
  };

  const bannerStyle = {
    width: '100%',
    display: 'block',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  };

  const bannerImg = (
    <div className={cx(carouselImageStyles.prebakedImageBackground, props?.carouselImageClassName)}>
      <Picture
        altText={blockItem.image?.image_alt_text ?? ''}
        desktop={imageData.desktop}
        mobile={imageData.mobile}
        tablet={imageData.tablet}
        imageClassName={carouselImageStyles.carouselImage}
        priority={itemIndex === 0}
        loading={itemIndex === 0 ? 'eager' : 'lazy'}
        tabIndex={blockItem.link ? -1 : tabindex}
      />
    </div>
  );
  return (
    <div style={bannerStyle} data-testid="preBakedHero">
      <div
        data-testid="bannerContainer"
        className={carouselImageStyles.bannerContainer}
        onClick={() => onBannerBtnClick(blockItem.image?.image_alt_text ?? '', itemIndex)}
        onAuxClick={(e) => {
          if (e.button === 1 && 'citrusInfo' in blockItem && blockItem.citrusInfo?.citrusAdId) {
            void reportCitrusClick(blockItem.citrusInfo.citrusAdId);
          }
        }}
      >
        {blockItem.link ? (
          <CMSLink
            className={carouselImageStyles.bannerImageLink}
            to={blockItem.link}
            setFocusToAnchorTag={setFocusToAnchorTag}
            data-testid="preBakedHero"
            tabIndex={-1}
          >
            {bannerImg}
          </CMSLink>
        ) : (
          bannerImg
        )}
      </div>
    </div>
  );
});
