/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackTargetDiv } from '../../interface';
type Props = {
  content: ContentStackTargetDiv;
};

export function CMSTarget({ content }: Props) {
  return <div id={content.attribute_id} />;
}
