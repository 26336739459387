/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackShopWithoutVehicle = () => {
  const utag = window.utag_data ?? {};
  const pagetype = utag.pageType ?? '';
  const pagename = utag.pageName ?? '';
  const sitesection = utag.siteSection ?? '';

  clickTrack({
    eventType: 'shop_without_vehicle',
    customLinkName: 'shop_without_vehicle-' + pagetype,
    pageName: pagename,
    pageType: pagetype,
    siteSection: sitesection,
  });
};
