/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  YMMEContentBlock,
  type YMMEContentBlockProps,
} from '@/features/ymme/components/YMMEContentBlock';
import { ContentStackYmme } from '../../interface';

type Props = {
  content: Omit<ContentStackYmme, 'theme' | 'title'>;
  redirectAfterVehicleChanged?: YMMEContentBlockProps['onUpdateVehicleSuccess'];
  removeBottomMargin?: boolean;
  removeMargins?: boolean;
  withPadding?: boolean;
  classes?: YMMEContentBlockProps['classes'];
  isProductFinderDisplayed?: boolean;
};

export function CMSYmme({
  classes,
  content,
  redirectAfterVehicleChanged,
  removeBottomMargin,
  removeMargins,
  withPadding,
  isProductFinderDisplayed,
}: Props) {
  const { layout, text, sub_text } = content;

  const layoutType = layout?.toLowerCase() as Lowercase<NonNullable<ContentStackYmme['layout']>>;

  return (
    <YMMEContentBlock
      ymmeTitle={text}
      ymmeDesc={sub_text}
      layoutType={layoutType}
      onUpdateVehicleSuccess={redirectAfterVehicleChanged}
      classes={classes}
      removeBottomMargin={removeBottomMargin}
      removeMargins={removeMargins}
      withPadding={withPadding}
      isProductFinderDisplayed={isProductFinderDisplayed}
    />
  );
}
