/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { HEADER_PRIMARY_KEY } from '@/features/header/api/getHeader';
import { getAxiosForClient } from '@/lib/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePreferredVehicleCookies } from '@/utils/analytics/dataLayer/preferredVehicleCookies';
import { getKey as getVehiclesKey, selector as getVehiclesSelector } from './getVehicles';
import { dispatchEvent } from '@/utils/eventPublisher';

const deleteDefaultVehicle = async () => {
  const response = await getAxiosForClient().delete(
    `/ecomm/b2c/v1/currentUser/vehicles/defaultVehicle`
  );
  // we're not able to type delete() function from axios due to old version that we're using
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return getVehiclesSelector(response.data);
};

const getKey = () => ['deleteDefaultVehicle'];

export const useDeleteDefaultVehicleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteDefaultVehicle(),
    mutationKey: getKey(),
    onSuccess: async (data) => {
      deletePreferredVehicleCookies();
      queryClient.setQueryData(getVehiclesKey(), data);
      await queryClient.refetchQueries([HEADER_PRIMARY_KEY]);
      dispatchEvent('preferredVehicleUpdated', { vehicleSet: false });
    },
  });
};
