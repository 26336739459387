/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View, Text } from '@az/starc-ui';
import cx from 'classnames';
import React from 'react';
import styles from './CMSHeadline.module.scss';
import { Headline, Props as HeadlineProps } from '@/components/Headline/Headline';

type Props = {
  children: React.ReactNode;
  nested?: boolean;
  fullWidth?: boolean;
  subHeading?: string;
  noGutter?: boolean;
  isBannerHeadline?: boolean;
} & HeadlineProps;

export const CMSHeadline = ({
  children,
  isBannerHeadline = false,
  nested = false,
  fullWidth = false,
  noGutter = false,
  subHeading,
  ...headlineProps
}: Props) => {
  return (
    <View
      className={cx(
        styles.headlineContainer,
        noGutter && styles.noGutter,
        nested && styles.reducedSpacing,
        isBannerHeadline && styles.bannerHeadline,
        fullWidth && styles.fullWidthHorizontalPadding
      )}
    >
      <Headline {...headlineProps}>{children}</Headline>
      {subHeading && (
        <Text size="087" className={styles.subHeading}>
          {subHeading}
        </Text>
      )}
    </View>
  );
};
