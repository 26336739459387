/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
const modalLinkRegex = new RegExp(/\[[A-Za-z-_]+\]/);

/**
 * Checks if the given URL is a modal link.
 * Modal links should be enclosed in square brackets and can only have letters, "-" and "_" characters.
 *
 * @example
 * isModalLink('[search_store_modal]') // true
 * @param {string} url - The URL to check.
 * @returns {boolean} True if the URL is a modal link, false otherwise.
 */
export function isModalLink(url: string): boolean {
  return modalLinkRegex.test(url);
}
