/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackHomeCarouselInteraction = (
  bannerName: string,
  slideNumber: number,
  linkName: string
) => {
  clickTrack({
    homeInternalPromoName: bannerName,
    homeCarouselRank: slideNumber.toString(),
    eventType: 'carouselPromotionHome',
    customLinkName: linkName,
  });
};
