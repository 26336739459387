/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import styles from './Headline.module.scss';
import { Grid } from '../Grid';
import { Text, TextProps } from '@az/starc-ui';

export type Props = {
  underline?: boolean;
  children?: string;
  className?: string;
  color?: TextProps['color'];
  emphasize?: 'all' | 'last';
  size?: TextProps['size'];
};

export function Headline({ underline = true, children, className, color, emphasize, size }: Props) {
  if (!children) {
    return null;
  }

  const headlineSize: NonNullable<TextProps['size']> = size ? size : '200';
  let title = children;
  let emphasized = '';

  if (emphasize === 'last') {
    const headlineParts = children.split(' ');
    emphasized = headlineParts.pop() ?? '';
    title = headlineParts.join(' ');
  } else if (emphasize === 'all') {
    emphasized = children;
    title = '';
  }

  return (
    <Grid data-testid="headline" container className={className} spacing={0}>
      <Grid item md={12} xs={12} lg={12}>
        <Text
          as="h2"
          size={headlineSize}
          weight={emphasize === 'last' ? 'light' : 'medium'}
          color={color}
          textCase="uppercase"
          className={styles.headline}
        >
          {title}
          {emphasized && (
            <Text as="strong" weight="heavy" className={styles.emphasizedText}>
              {emphasized}
            </Text>
          )}
        </Text>
      </Grid>
      {underline && (
        <Grid data-testid="underline" item md={12} xs={12}>
          <div className={`${styles.separator}`} />
        </Grid>
      )}
    </Grid>
  );
}
