/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import { useQuery } from '@tanstack/react-query';
import type { CitrusBannerResponse } from '../interface';
import { useAppState } from '@/stores/AppState';
import { useFeatureFlag } from '@/features/globalConfig';
import { getDecodedCookies } from '@/utils/cookie';
import { getCitrusCatalogId } from '../utils/getCitrusCatalogId';
import { getCitrusContentStandardId } from '../utils/getCitrusContentStandardId';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';

const URL = `/api/citrus/home`;
const PROXY_URL = '/v1/ads/bannerx';

const getCitrusHomepageDataWithProxy = async () => {
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const eCookieId = cookies.get('eCookieId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();
  const response = await getAxiosForClient().post<CitrusBannerResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId || null,
      sessionId: rewardsId || eCookieId,
      placement: 'homepage-banner-x',
      catalogId: getCitrusCatalogId(forceCitrusProd),
      contentStandardId: getCitrusContentStandardId(forceCitrusProd),
      options: {
        filterMode: 'AndOr',
      },
      productFilters: [],
      bannerSlotIds: [
        {
          slotId: 'carousel-banner',
          maxNumberOfAds: 5,
        },
        {
          slotId: 'below-carousel-banner',
          maxNumberOfAds: 1,
        },
        {
          slotId: 'midpage-banner',
          maxNumberOfAds: 1,
        },
      ],
    },
    axiosConfig
  );
  return selector(response.data);
};

const getCitrusHomepageData = async (eCookieId: string) => {
  const response = await getAxiosForClient().get<CitrusBannerResponse>(URL, {
    params: {
      eCookieId,
    },
  });
  return selector(response.data);
};

const selector = (data: CitrusBannerResponse): CitrusBannerResponse => data;

const getCitrusHomepageKey = () => ['citrus', 'home'] as const;

type Options = {
  enabled?: boolean;
};

export const useCitrusHomepageData = (options: Options) => {
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const { eCookieId } = useAppState().state;
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;
  return useQuery({
    enabled: citrusEnabled && (options.enabled ?? true),
    queryKey: getCitrusHomepageKey(),
    queryFn: () =>
      !CITRUS_PROXY_DISABLED ? getCitrusHomepageDataWithProxy() : getCitrusHomepageData(eCookieId),
  });
};
