/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import { Actionable, View, Icon, ChevronRight } from '@az/starc-ui';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { YMMEModal } from '../YMMEModal';
import { useLabel } from '@/hooks/useLabels';

const vehicleIconSource = '/images/vehicle-new.svg';

const blurFocusedElement = () => {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  } else {
    document.body.focus();
  }
};

type SlimMobileInlineYMMEProps = {
  showYmme: boolean;
  openYmmeWidget: () => void;
  closeYmmeWidget: () => void;
  onUpdateVehicleSuccess?: () => void;
};

const SlimMobileInlineYMME = ({
  showYmme,
  openYmmeWidget,
  closeYmmeWidget,
  onUpdateVehicleSuccess,
}: SlimMobileInlineYMMEProps) => {
  const { data: headerData } = useHeaderData();
  const vehicle = getPreferredVehicle(headerData);
  const lblAddVehicleForExactFit = useLabel('label_YMME_AddYourVehicleForAnExactFit');

  const closeYmme = () => {
    closeYmmeWidget();
    setTimeout(blurFocusedElement, 100);
  };

  if (vehicle != null) {
    return null;
  }

  return (
    <>
      <View padding={[4, 0, 0, 0]}>
        <View direction="column" padding={[4, 3]} className={styles.container}>
          <Actionable onClick={openYmmeWidget} className={styles.button}>
            <View justify="center" align="center">
              <img src={vehicleIconSource} width={22} height={16} alt="" decoding="async" />
            </View>
            <View>{lblAddVehicleForExactFit}</View>
            <View direction="row" justify="end" className={styles.chevronIcon}>
              <Icon size={3.5} svg={ChevronRight} />
            </View>
          </Actionable>
        </View>
      </View>
      <YMMEModal
        showYmme={showYmme}
        closeYmmeWidget={closeYmme}
        onUpdateVehicleSuccess={onUpdateVehicleSuccess}
      />
    </>
  );
};

export default SlimMobileInlineYMME;
