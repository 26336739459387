/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { CitrusBanner } from '../interface';

export function isCitrusBannerWithLink(
  item: CitrusBanner
): item is CitrusBanner & { ctaLink: string } {
  return !!item.ctaLink;
}
