/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMediaQuery } from '@/hooks/useMediaQuery';
import type { CarouselItem } from '../../interface';

/**
 * @param part index of current item
 * @param whole amount of total items
 * @returns `${part} of ${whole}`
 */
export const partOfWholeToString = (part: number, whole: number) => {
  return `${part} of ${whole}`;
};

/**
 * @param altText image alt-text for banner
 * @param part index of current item
 * @param whole amount of total items
 * @returns `${altText} ${part} of ${whole}`
 */
export const ariaLabelForCurrentSlide = ({
  part,
  whole,
  altText,
}: {
  part: number;
  whole: number;
  altText?: string;
}) => {
  return `${!!altText && altText} ${partOfWholeToString(part, whole)}`.trim();
};

/** Determines color set in ContentStack with optional responsive color
 * overrides for mobile, tablet, and desktop
 * @param carouselItem current banner displayed in Super Duper Hero
 * @returns color hex value as a string, either '#FFFFFF' or '#202124'
 */
export const useLegalLinkColor = (carouselItem: CarouselItem) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const getLegalLinkColor = () => {
    const sizeToString = () => {
      switch (true) {
        case isMobile:
          return 'Mobile';
        case isTablet:
          return 'Tablet';
        default:
          return 'Desktop';
      }
    };
    const hexColor = (color?: string): string => {
      switch (color) {
        case 'White':
          return 'var(--st-color-secondary)';
        case 'Black':
        default:
          return 'var(--st-color-primary)';
      }
    };

    if (!('legal_link_color' in carouselItem)) {
      return 'var(--st-color-primary)';
    }

    const { ...legal_link_color } = carouselItem.legal_link_color;
    let hex =
      legal_link_color.default_color && typeof legal_link_color.default_color === 'string'
        ? hexColor(legal_link_color.default_color)
        : '#202124';
    legal_link_color.group?.forEach((element) => {
      if (element.device === sizeToString()) {
        hex = hexColor(element.color);
      }
    });
    return hex;
  };
  const legalLinkColor = getLegalLinkColor();
  return legalLinkColor;
};
