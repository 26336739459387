/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ModalLink } from '@/components/ModalLink/ModalLink';
import { SmartLink as Link, getPath } from '@/utils/smartLink';
import { isModalLink } from '../../utils/isModalLink';

type CMSLinkProps = React.ComponentProps<typeof Link>;

export function CMSLink(props: CMSLinkProps) {
  const path = getPath(props.to);

  if (isModalLink(path)) {
    return <ModalLink modalId={path}>{props.children}</ModalLink>;
  }

  return <Link {...props} />;
}
