/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { CitrusBanner } from '../interface';
import type { ContentStackBanner } from '@/features/contentstack/interface';

export const mapCitrusBannerToContentStackBanner = (
  citrusBanner: CitrusBanner & { ctaLink: string }
): ContentStackBanner => {
  let ctaLink = citrusBanner.ctaLink ?? '';

  if (ctaLink.includes('://')) {
    const link = new URL(ctaLink);
    ctaLink = `${link.pathname}${link.search}`;
  }

  return {
    title: '',
    asset_size: 'Medium', //it's not used in CMSBanner but is required in ContentStackBanner type
    image: {
      image_alt_text: citrusBanner.heroImageAltText,
      image_url: citrusBanner.backgroundImage,
      attributes: [
        {
          device: 'Tablet',
          device_image: citrusBanner.secondaryBackgroundImage,
        },
        {
          device: 'Mobile',
          device_image: citrusBanner.heroImage,
        },
      ],
    },
    legal: {
      text:
        citrusBanner.additionalFields?.find((field) => field.id === 'disclaimer-text')?.value ?? '',
    },
    link: ctaLink,
  };
};
