/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import { useDeviceTypeByMediaQuery } from '@/hooks/useDeviceTypeByMediaQuery';
import type { ContentStackCertona } from '../interface';

/**
 * Hook to get the responsive container style for a Certona component based on the current device type.
 *
 * @param containerStyles - The container styles object from the ContentStackCertona data.
 * @returns The responsive container style string for the current device type.
 */
const useResponsiveCertonaContainerStyle = (
  containerStyles: ContentStackCertona['container_style']
) => {
  const deviceType = useDeviceTypeByMediaQuery();

  return useMemo(() => {
    return (
      containerStyles?.device_specific_overrides?.find(
        (override) => override.device?.toLowerCase() === deviceType
      )?.container_style ??
      containerStyles?.default_container_style ??
      ''
    );
  }, [containerStyles, deviceType]);
};

export { useResponsiveCertonaContainerStyle };
