/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Head from 'next/head';
import {
  forwardRef,
  type CSSProperties,
  type ImgHTMLAttributes,
  type ReactEventHandler,
} from 'react';
import { BREAKPOINTS } from '@/constants/screen';

type ImageConfiguration = {
  src: string;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
};

export type PictureProps = {
  altText: string;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
  fetchPriority?: ImgHTMLAttributes<HTMLImageElement>['fetchPriority'];
  priority?: boolean;
  imageClassName?: string;
  mobile: ImageConfiguration;
  tablet: ImageConfiguration;
  desktop: ImageConfiguration;
  tabIndex?: number | undefined;
  'data-testid'?: string;
  onError?: ReactEventHandler<HTMLImageElement>;
};

export const Picture = forwardRef<HTMLImageElement, PictureProps>(function PictureComponent(
  {
    mobile,
    tablet,
    desktop,
    loading = 'lazy',
    fetchPriority,
    imageClassName,
    altText,
    priority = false,
    tabIndex,
    'data-testid': dataTestId,
    onError,
  }: PictureProps,
  ref
) {
  const mobileMedia = `(max-width: ${BREAKPOINTS.MOBILE_MAX}px)`;
  const tabletMedia = `(min-width: ${BREAKPOINTS.TABLET_MIN}px) and (max-width: ${BREAKPOINTS.TABLET_MAX}px)`;
  const desktopMedia = `(min-width: ${BREAKPOINTS.DESKTOP_MIN}px)`;
  return (
    <>
      <picture>
        <source
          media={mobileMedia}
          srcSet={mobile.src}
          width={mobile.width}
          height={mobile.height}
        />
        <source
          media={tabletMedia}
          srcSet={tablet.src}
          width={tablet.width}
          height={tablet.height}
        />
        <img
          // In React 18.2.0 or older, we must use lowercase prop
          // to avoid "Warning: Invalid DOM property". In React 18.3.0 or newer, we must use camelCase
          // prop to avoid "Warning: Invalid DOM property".
          // See https://github.com/facebook/react/pull/25927
          fetchpriority={priority ? 'high' : fetchPriority}
          width={desktop.width}
          height={desktop.height}
          loading={priority ? 'eager' : loading}
          src={desktop.src}
          alt={altText}
          aria-label={altText === '' ? undefined : altText}
          className={imageClassName}
          decoding="async"
          data-testid={dataTestId}
          onError={onError}
          tabIndex={tabIndex}
          ref={ref}
        />
      </picture>
      {priority && (
        <Head>
          <link
            rel="preload"
            as="image"
            key={mobile.src}
            href={mobile.src}
            media={mobileMedia}
            // In React 18.2.0 or older, we must use lowercase prop
            // to avoid "Warning: Invalid DOM property". In React 18.3.0 or newer, we must use camelCase
            // prop to avoid "Warning: Invalid DOM property".
            // See https://github.com/facebook/react/pull/25927
            fetchpriority="high"
          />
          <link
            key={tablet.src}
            rel="preload"
            as="image"
            href={tablet.src}
            media={tabletMedia}
            fetchpriority="high"
          />
          <link
            key={desktop.src}
            rel="preload"
            as="image"
            href={desktop.src}
            media={desktopMedia}
            fetchpriority="high"
          />
        </Head>
      )}
    </>
  );
});
