/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View } from '@az/starc-ui';
import styles from './STARCTypography.module.scss';

type Props = {
  children: React.ReactNode;
};

export const STARCTypography = ({ children }: Props) => {
  return <View className={styles.STARCTypography}>{children}</View>;
};
